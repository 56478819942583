import useTranslation from "@cospex/client/hooks/useTranslation";
import PhoneIcon from "@mui/icons-material/Phone";
import { Stack } from "@mui/material";
import { red } from "@mui/material/colors";

type IContactFormNumber = {
  contactNumbers?: { [key: string]: string };
};

const ContactFormNumber = ({ contactNumbers = {} }: IContactFormNumber) => {
  const { i18n } = useTranslation();
  const number = contactNumbers[i18n.language];
  if (!number) return null;

  return (
    <Stack
      sx={{
        mb: 2,
        pb: 2,
        borderBottom: "2px solid #f2f2f2",
        alignItems: "center",
        fontWeight: "bold",
        fontSize: 22,
        flexDirection: "row",
        gap: 1,
      }}
    >
      <PhoneIcon sx={{ color: red[500] }} />
      {number}
    </Stack>
  );
};

export default ContactFormNumber;
