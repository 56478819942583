export const getLanguage = (acceptedLanguages: string[]) => {
  const urlParams = new URLSearchParams(window.location.search);
  const langToSet =
    urlParams.get("lang") ||
    localStorage.getItem("lng") ||
    window.navigator.language.split("-")[0];

  const lang = acceptedLanguages.includes(langToSet)
    ? langToSet
    : acceptedLanguages[0];

  localStorage.setItem("lng", lang);

  return lang;
};
